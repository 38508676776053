
import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
      },
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
    }),

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      await this.doRegisterEmailAndPassword({
          email: this.model.email,
          password: this.model.password,
        },
      );
    },
  },
};
